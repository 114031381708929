import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import { useParams } from "react-router-dom";
import { Row, Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import { Requirementcontsnt } from "./Requirementcontsnt";

const AuditionRequirements = () => {
    let { id } = useParams();
    const [requirementShow, setRequirementShow] = useState(false)
    const [requirementData, setRequirementData] = useState([])
    const [Data, setData] = useState({})
    const GetRequirementdata = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/audition-requirements/?audition=${id}`)
            console.log(responce)
            setRequirementData(responce.data.results);
        }
        catch (error) {
            console.log(error)
        }
    }
    const AudiRequirements = (data, setFieldErrors) => {
        console.log(Data)
        const orderTopost = {
            ...data,
            audition: id
        }
        console.log(orderTopost)
        if (data?.from_age >= data?.to_age) {
            let errorMessage = "From age must be less than To age."
            setFieldErrors(prev => ({ ...prev, ['from_age']: errorMessage, ['to_age']: errorMessage }));
        } else {
            if (Data.id) {
                axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, orderTopost).then(res => {
                    console.log(res)
                    GetRequirementdata()
                    setRequirementShow(false)
                }).catch(error => {
                    console.log(error.response.data)
                    // setpopupType("error");
                })
            } else {
                axiosConfig.post("/accounts/audition-requirements/", orderTopost).then(res => {
                    console.log(res)
                    GetRequirementdata()
                    setRequirementShow(false)
                }).catch(error => {
                    console.log(error.response.data)
                    // setpopupType("error");
                })
            }
        }
    }
    useEffect(() => {
        GetRequirementdata()
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-9">
                    <h6>Requirements</h6>
                </div>
                <div className="col-3">
                    {/* <button type="button" className="btn btn-success apply-new-craft" onClick={() => setshowApply(true)} >Apply New Craft</button> */}
                    <button className="btn btn-success apply-new-craft" onClick={() => {
                        setData({})
                        setRequirementShow(true)
                    }}>+Add Requirements</button>
                </div>
            </div>
            <div className="investment-screen">
                <div className="row">
                    {requirementData.map((requirement, index) => (
                        <Requirementcontsnt Data={requirement} GetData={GetRequirementdata} />
                    ))}
                </div>
            </div>
            <Modal
                size="lg"
                show={requirementShow}
                onHide={() => {
                    setData({})
                    setRequirementShow(false)
                }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Audition Requirements
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={AudiRequirements} error={Error} />
                </Modal.Body>
            </Modal>
        </>
    )
}
export { AuditionRequirements };