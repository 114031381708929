import React, { useState, useEffect } from "react";
import { NotFound } from "../../Pages";
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';
import { FaFileExcel, FaFileWord, FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { IoMdDownload } from "react-icons/io";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdInfo } from 'react-icons/md';
const FormParser = ({ modelObject, formData, formSubmit, error, button, buttonTitle, readonly, submitInProgress, id }) => {
    const [Data, SetData] = useState({})
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(true);
    const [dynamicOptions, SetDynamicOptions] = useState({})
    const [Option, setOption] = useState({});
    const [Error, SetError] = useState("")
    const [FieldErrors, setFieldErrors] = useState({});
    const [nextPageUrl, setNextPageUrl] = useState('');
    const [search, setSearch] = useState('');
    const [SelectDynamicOptions, SetSelectDynamicOptions] = useState([])
    const [fetchedIds, setFetchedIds] = useState(new Set());
    const [selectedOptions, setSelectedOptions] = useState([])
    const [FilterData, SetFilterdata] = useState([])
    const [Name, SetName] = useState("")
    const GetData = async () => {
        try {
            for (const field_obj of field_objects) {
                if (field_obj.fieldType === "dynamicdropdown" || field_obj.fieldType === "Phone" || field_obj.fieldType === "Doctor") {
                    if (field_obj.options_url && field_obj.options_url.includes('?')) {
                        const response = await axiosConfig.get(`${field_obj.options_url}&is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                    else {
                        const response = await axiosConfig.get(`${field_obj.options_url}?is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                }
                if (field_obj.fieldType === "HandleInfinitiSelect") {
                    if (field_obj.options_url && field_obj.options_url.includes('?')) {
                        const response = await axiosConfig.get(`${field_obj.options_url}&is_suspended=${formData ? "" : false}&search=${search}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        if (formData) {
                            setSearch(formData.city_name)
                        }
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                    else {
                        const response = await axiosConfig.get(`${field_obj.options_url}?is_suspended=${formData ? "" : false}&search=${search}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleScroll = async (fieldName, fieldType) => {
        if (!loading && nextPageUrl) {
            setLoading1(true);
            try {
                const product = await axiosConfig.get(
                    nextPageUrl[fieldName].replace(/^http:/, 'https:')
                );
                const product_data = product.data.results;

                setNextPageUrl(prevOptions => ({
                    ...prevOptions,
                    [fieldName]: product.data.next
                }));
                if (fieldType === "dynamicSelectdropdown") {
                    SetSelectDynamicOptions(prevOptions => ({
                        ...prevOptions,
                        [fieldName]: [
                            ...(prevOptions[fieldName] || []), // Preserve existing data
                            ...product_data // Append new data
                        ]
                    }));
                }
                SetDynamicOptions(prevOptions => ({
                    ...prevOptions,
                    [fieldName]: [
                        ...(prevOptions[fieldName] || []), // Preserve existing data
                        ...product_data // Append new data
                    ]
                }));
            } catch (error) {
                console.error(`Error fetching data for ${fieldName}:`, error);
            }
            try {

            } finally {
                setLoading1(false);
            }
        }
    };
    const handleSearch = (value) => {
        setSearch(value)
    }
    const GetMultiData = async () => {
        let data = []
        try {
            for (const field_obj of field_objects) {
                if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
                    if (field_obj.options_url && field_obj.options_url.includes('?')) {
                        const response = await axiosConfig.get(`${field_obj.options_url}&is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                    else {
                        const response = await axiosConfig.get(`${field_obj.options_url}?is_suspended=${formData ? "" : false}`);
                        setNextPageUrl(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.next
                        }));
                        SetDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [field_obj.fieldName]: response.data.results
                        }));
                    }
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    const HandleRadioChange = (e, ItemName) => {
        const { name } = e.target
        SetData({ ...Data, [name]: ItemName });
    }
    const SelectOptions = (id, name) => {
        field_objects.forEach(item => {
            if (item.fieldType === "dynamicSelectdropdown") {
                if (item.renderField === name) {
                    let url = `${item.options_url}${id}&is_suspended=${formData ? "" : false}`
                    axiosConfig.get(url).then(res => {
                        if (item.isMulti) {
                            SetSelectDynamicOptions(prevOptions => ({
                                ...prevOptions,
                                [item.fieldName]: [
                                    ...(prevOptions[item.fieldName] || []),
                                    ...res.data.results
                                ]
                            }));
                            setNextPageUrl(prevOptions => ({
                                ...prevOptions,
                                [item.fieldName]: res.data.next
                            }));
                        }
                        else {
                            SetSelectDynamicOptions(prevOptions => ({
                                ...prevOptions,
                                [item.fieldName]: res.data.results
                            }));
                            setNextPageUrl(prevOptions => ({
                                ...prevOptions,
                                [item.fieldName]: res.data.next
                            }));
                        }
                        setFetchedIds(prevFetchedIds => new Set(prevFetchedIds).add(id));
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }
        })
    }
    const editDataRender = async (formData) => {
        field_objects.forEach(async (field_obj, idx) => {
            if (formData) {
                Data[field_obj.fieldName] = formData[field_obj.fieldName]
                SetData(formData)
                if (field_obj.fieldType === "dynamicSelectdropdown") {
                    if (formData[field_obj.renderField]) {
                        let url = `${field_obj.options_url}${formData[field_obj.renderField]}`
                        await axiosConfig.get(url).then(res => {
                            SetSelectDynamicOptions(prevOptions => ({
                                ...prevOptions,
                                [field_obj.fieldName]: res.data.results
                            }));
                            const find = res.data.results.find(find => find.id === formData[field_obj.fieldName])
                            setOption({ value: find.id, label: find[field_obj.displayField] })
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }
                if (field_obj.fieldType === "dynamicMultiSelectdropdown" && dynamicOptions[field_obj.fieldName]) {
                    let data = []
                    formData && formData[field_obj.fieldName] && formData[field_obj.fieldName].forEach(value => {
                        const findData = dynamicOptions[field_obj.fieldName].find(find => find.id === value)
                        data.push(findData)
                    })
                    setSelectedOptions(data)
                }
            }
        })
        setTimeout(function () {
            setLoading(false);
        }.bind(this), 500)
    }
    useEffect(() => {
        setTimeout(function () {
            setLoading(false);
        }.bind(this), 1000)
        GetData();
        GetMultiData()
    }, [search])
    useEffect(() => {
        if (formData) {
            editDataRender(formData)
        }
    }, [formData])
    const HandleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        SetData({ ...Data, [name]: value })
        validateField(name, value)
        SetName(value)
    }
    const handleSelectChange = (selectedOption, name) => {
        SetData({ ...Data, [name]: selectedOption.value });
        field_objects.forEach(item => {
            if (item.fieldType === "dynamicSelectdropdown") {
                if (item.renderField === name) {
                    SelectOptions(selectedOption.value, name)
                }
            }
        })
    };
    const handleMultiSelectChange = (selectedOption, Value, name) => {
        let data = []
        let filteredData = [];
        if (selectedOption.length === 0) {
            SetData({ ...Data, [name]: [] });
            setFetchedIds(new Set());
            field_objects.forEach(item => {
                if (item.renderField === name && item.fieldType === "dynamicSelectdropdown") {
                    SetSelectDynamicOptions(prevOptions => ({
                        ...prevOptions,
                        [item.fieldName]: filteredData
                    }));
                }
            });
            return;
        }
        selectedOption.forEach(res => {
            data.push(res.value)
            if (!fetchedIds.has(res.value)) {
                SelectOptions(res.value, name);
            } else if (fetchedIds.has(res.value)) {
                field_objects.forEach(item => {
                    if (item.renderField === name && item.fieldType === "dynamicSelectdropdown") {
                        const find_data = SelectDynamicOptions[item.fieldName].filter(option => option.state === res.value);
                        filteredData = [...filteredData, ...find_data];
                        SetSelectDynamicOptions(prevOptions => ({
                            ...prevOptions,
                            [item.fieldName]: filteredData
                        }));
                        setFetchedIds(prevFetchedIds => {
                            const newFetchedIds = new Set(prevFetchedIds);
                            newFetchedIds.add(res.value);
                            return newFetchedIds;
                        });
                    }
                })
            }
        })
        SetData({ ...Data, [name]: data });
    };
    const HandleImages = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        if (file) {
            const name = e.target.name;
            const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];
            const maxSizeMB = 10;
            if (!allowedTypes.includes(file.type)) {
                alert("Only JPEG, JPG, PNG, and GIF files are allowed.");
                e.target.value = null;
                return;
            }
            if (file.size > maxSizeMB * 1024 * 1024) {
                alert("File size exceeds 10MB limit.");
                e.target.value = null;
                return;
            }
            const value = file;
            SetData({ ...Data, [name]: value });
        }
    }
    const HandleMultiPulFiles = (e) => {
        e.preventDefault()
        const file = e.target.files;
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const Handlecheckboxchange = (e) => {
        const name = e.target.name;
        const value = e.target.checked
        SetData({ ...Data, [name]: value })
    }
    const handleSelect = (selectedOption, name) => {
        SetData({ ...Data, ["country_calling_code"]: selectedOption.value })
    };
    const handleSelectDoctor = (selectedOption, name) => {
        SetData({ ...Data, ["title"]: selectedOption.value })
    };
    const OnSubmit = (e) => {
        e.preventDefault();
        const requiredFieldErrors = validateRequiredFields();
        if (requiredFieldErrors) {
            setFieldErrors(requiredFieldErrors);
            return;
        }
        setFieldErrors({});
        formSubmit(Data, setFieldErrors);
    }
    const validateField = (name, value) => {
        const regex = {
            country_name: /^[a-zA-Z]+$/,
            state_name: /^[a-zA-Z]+$/,
            city_name: /^[a-zA-Z]+$/,
            pincode: /^[0-9]+$/,
            email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            cpt_code: /^[0-9]+$/,
        };
        const fields = field_objects.find(fields => fields.fieldName === name);
        if (value.trim() === '') {
            setFieldErrors({});
        } else {
            const isInputValid = regex[name] ? regex[name].test(value) : true;
            if (!isInputValid) {
                let name = fields.fieldName;
                setFieldErrors({ [name]: `Enter a valid ${fields.label}` });
            } else {
                setFieldErrors({});
            }
        }
    };
    let field_objects = []
    modelObject.orderBy.forEach((order_obj, idx) => {
        modelObject.fields.forEach((field_obj, index) => {
            if (field_obj.fieldName === order_obj) {
                let flagAddClass = true
                modelObject.layoutSpecificFieldClasses.forEach((classObj, iex) => {
                    if (classObj[field_obj.fieldName]) {
                        field_obj['groupClass'] = classObj[field_obj.fieldName]
                        flagAddClass = false
                    }
                })
                if (modelObject.layoutClass && flagAddClass) {
                    field_obj['groupClass'] = modelObject.layoutClass
                }
                field_objects.push(field_obj)
            }
        })
    })
    const validateRequiredFields = () => {
        const requiredFieldErrors = {};
        field_objects.forEach((fieldItem) => {
            if (fieldItem.required && !Data[fieldItem.fieldName]) {
                requiredFieldErrors[fieldItem.fieldName] = `${fieldItem.label} is required`;
            }
        });
        return Object.keys(requiredFieldErrors).length > 0 ? requiredFieldErrors : null;
    };
    function getFileIcon(documentUrl) {
        if (documentUrl.type) {
            if (documentUrl.type === 'application/pdf') {
                return <FaFilePdf color='red' size={20} />;
            } else if (documentUrl.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                documentUrl.type === 'application/vnd.ms-excel') {
                return <FaFileExcel size={20} />;
            } else if (documentUrl.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                documentUrl === 'application/msword') {
                return <FaFileWord size={20} />;
            } else if (documentUrl.type === 'text/csv') {
                return <FaFileCsv size={20} />;
            }
            else if (documentUrl.type === "application/x-msdownload") {
                return <IoMdDownload size={20} />
            }
            else {
                return (
                    <img
                        src={URL.createObjectURL(documentUrl)}
                        width="30"
                        className="form-edit-img"
                    />
                )
            }
        }
        else {
            if (documentUrl.endsWith(".pdf")) {
                return <FaFilePdf color='red' size={20} />;
            } else if (documentUrl.endsWith(".xlsx") || documentUrl.endsWith(".xls")) {
                return <FaFileExcel size={20} />;
            } else if (documentUrl.endsWith(".docx") || documentUrl.endsWith(".doc")) {
                return <FaFileWord size={20} />;
            } else if (documentUrl.endsWith(".csv")) {
                return <FaFileCsv size={20} />;
            }
            else if (documentUrl.endsWith(".exe")) {
                return <IoMdDownload size={20} />;
            }
            else {
                return (
                    <img
                        src={documentUrl}
                        width="30"
                        className="form-edit-img"
                    />
                )
            }
        }
    }
    const countries = [];
    const defaultCountry = countries.find(country => country?.code === 'IN');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry?.mobileCode);
    const handleInputChange = (e) => {
        setPhoneNumber(e.target.value);
    };
    const handleCountryChange = (value, data, name) => {
        SetData({ ...Data, [name]: value })
    };
    const handleOptionClick = (option) => {
        SetName(option)
        SetFilterdata([])
    };
    return (
        <>
            <div className="container-form">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('./images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <form onSubmit={OnSubmit} encType="multipart/form-data">
                        <div className="Loginerror">
                            <p>{error}</p>
                        </div>
                        <div className="row">
                            {field_objects &&
                                field_objects.map((fieldItem, i) => {
                                    const isRequired = fieldItem.required ? '*' : '';
                                    const hasError = Error && Error.includes(`Enter a valid ${fieldItem.label}`);
                                    const isRequiredAndEmpty = fieldItem.required && !Data[fieldItem.fieldName];
                                    return (
                                        <div key={fieldItem.fieldName} className={fieldItem.groupClass}>
                                            {fieldItem.fieldType === "string" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label} <span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="text" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readonly} style={{ textTransform: fieldItem.textTransform ? "uppercase" : "" }} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "email" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="email" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "url" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="nuput-box">
                                                        <input type="url" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "boolean" &&
                                                <div className="form-group">
                                                    <label className="form-check-label ari-radio-label">
                                                        <input className="ari-radio" type="checkbox" name={fieldItem.fieldName} checked={Data[fieldItem.fieldName]} onChange={Handlecheckboxchange} required={fieldItem.required} disabled={readonly} /> {fieldItem.label}
                                                    </label>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    Tooltip for <strong>{fieldItem.label}</strong>.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button style={{ border: "none", background: "#FFF" }}>
                                                                <MdInfo />
                                                            </button>
                                                        </OverlayTrigger>
                                                    )}
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "file" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="file" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleImages} required={!Data[fieldItem.fieldName] && fieldItem.required} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>}
                                            {fieldItem.fieldType === "fileimage" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    {Data && Data[fieldItem.fieldName] && (
                                                        <a href={
                                                            typeof Data[fieldItem.fieldName] === 'string'
                                                                ? Data[fieldItem.fieldName]
                                                                : URL.createObjectURL(Data[fieldItem.fieldName])
                                                        } target="_blank"
                                                        >
                                                            {getFileIcon(Data[fieldItem.fieldName])}
                                                        </a>
                                                    )}
                                                    <div className="input-box">
                                                        <input type="file" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleFiles} required={!Data[fieldItem.fieldName] && fieldItem.required} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "date" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="date" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "time" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="time" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "integer" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="number" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "mobile" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box" style={{ position: "relative" }}>
                                                        <input type="tel" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} minLength={fieldItem.min_length ? fieldItem.min_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readonly} required={fieldItem.required} onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "Phone" &&
                                                <div className={`form-group ${hasError || (FieldErrors[fieldItem.fieldName] && 'has-error') ? 'has-error' : ''}`}>
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box" style={{ position: "relative" }}>
                                                        <div>
                                                            <Select styles={{ position: "relative" }} className={FieldErrors[fieldItem.fieldName] ? "invalid-error w-1" : "w-1"} options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                                { code: res.country_calling_code, "value": res.id, "label": `${res.country_calling_code} ${res[fieldItem.displayField]}` }
                                                            ))}
                                                                value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data["country_calling_code"])} onChange={(selectedOption) => handleSelect(selectedOption, fieldItem.fieldName)} isDisabled={readonly} autoComplete="off" />
                                                        </div>

                                                        <input type="text" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "password" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <input type="password" className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readonly} />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "radio" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <br />
                                                    {fieldItem.options &&
                                                        fieldItem.options.map((optionItem, index) => {
                                                            return (
                                                                <div className="form-check-inline" key={index}>
                                                                    <label className="form-check-label ari-radio-label">
                                                                        <input type="radio" className="ari-radio" name={fieldItem.fieldName} checked={Data[fieldItem.fieldName] === optionItem} defaultValue={Data[fieldItem.fieldName]} onChange={(e) => HandleRadioChange(e, optionItem)} required={fieldItem.required} disabled={readonly} />{optionItem}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {/* {fieldItem.required && (
                                                        <OverlayTrigger
                                                        placement="right"
                                                        overlay={
                                                          <Tooltip id="tooltip-top">
                                                            Tooltip for <strong>{fieldItem.label}</strong>.
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <button style={{border:"none",background:"#FFF"}}>
                                                        <MdInfo />
                                                        </button>
                                                      </OverlayTrigger>
                                                    )} */}
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {fieldItem.fieldType === "textarea" &&
                                                <div className="form-group">
                                                    <label>{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <textarea className={`form-control ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} rows="2" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} disabled={readonly}></textarea>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {/* {
                                                fieldItem.fieldType === "dropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <div>
                                                            <select className={`form-select ${hasError || (FieldErrors[fieldItem.fieldName] && 'invalid-error') ? 'invalid-error' : ''}`} name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required} disabled={readonly} >
                                                                <option value="">--Select--</option>
                                                                {
                                                                    fieldItem.options.map((optionItem, i) => {
                                                                        return (
                                                                            <option value={optionItem} key={i}>{optionItem}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            } */}
                                            {
                                                fieldItem.fieldType === "dropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <div>
                                                            <div>
                                                                <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={fieldItem.options.map((res) => (
                                                                    { "value": res.value ? res.value : res, "label": res.label ? res.label : res }
                                                                ))} placeholder={fieldItem.label} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                                    isLoading={loading1}
                                                                    onInputChange={handleSearch} value={fieldItem.options.map(res => ({ "value": res.value ? res.value : res, "label": res.label ? res.label : res })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readonly} autoComplete="off" />
                                                            </div>
                                                            {/* <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={fieldItem.options} placeholder={fieldItem.label} defaultValue={fieldItem.options.find(res => res === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readonly} /> */}
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <div>
                                                            <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                                { "value": res.id, "label": res[fieldItem.displayField] }
                                                            ))} placeholder={fieldItem.label} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                                isLoading={loading1}
                                                                onInputChange={handleSearch} value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readonly} autoComplete="off" />
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={SelectDynamicOptions[fieldItem.fieldName] && SelectDynamicOptions[fieldItem.fieldName].map((res) => (
                                                            { "value": res.id, "label": res[fieldItem.displayField] }
                                                        ))} placeholder={fieldItem.label} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName, fieldItem.fieldType)}
                                                            isLoading={loading1}
                                                            onInputChange={handleSearch} value={SelectDynamicOptions[fieldItem.fieldName] && SelectDynamicOptions[fieldItem.fieldName].map((res) => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(find => find.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readonly} autoComplete="off" />
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicMultiSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    <Select isMulti options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label}
                                                        onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                        isLoading={loading1}
                                                        onInputChange={handleSearch}
                                                        value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ value: res.id, label: res[fieldItem.displayField] })).filter(option => (Data[fieldItem.fieldName] || []).includes(option.value))}
                                                        onChange={(selectedOption) => handleMultiSelectChange(selectedOption, selectedOption.value, fieldItem.fieldName)} isDisabled={readonly} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "mulitipulFiles" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                <input className="form-control" type="file" id="formFileMultiple" name={fieldItem.fieldName} multiple onChange={HandleMultiPulFiles} required={fieldItem.required} disabled={readonly} />
                                            </div>
                                            }
                                            {fieldItem.fieldType === "status" && <div key={i} className="form-group form-check form-switch">
                                                <div>
                                                    <label className="form-check-label">STATUS</label>
                                                </div>
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} disabled={readonly} />

                                            </div>
                                            }
                                            {
                                                fieldItem.fieldType === "HandleInfinitiSelect" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}<span className="required-star">{isRequired}</span>:</label>
                                                    <div className="input-box">
                                                        <div>
                                                            <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                                { "value": res.id, "label": res[fieldItem.displayField] }
                                                            ))} placeholder={fieldItem.label} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                                isLoading={loading1}
                                                                onInputChange={handleSearch} value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} isDisabled={readonly} autoComplete="off" />
                                                        </div>
                                                        {FieldErrors[fieldItem.fieldName] && (
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Tooltip for <strong>{fieldItem.label}</strong>.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button style={{ border: "none", background: "#FFF" }}>
                                                                    <MdInfo />
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    {FieldErrors[fieldItem.fieldName] && (
                                                        <div className="invalid">
                                                            {FieldErrors[fieldItem.fieldName]}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {!readonly &&
                            <button type="submit" className={`btn btn-primary ${button ? button : ""}`} disabled={submitInProgress}>
                                {submitInProgress ? ("Loading ...") : (buttonTitle ? buttonTitle : "Submit")}
                            </button>
                        }
                    </form>

                )}
            </div>

        </>
    )
}
export { FormParser }