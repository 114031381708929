import React, { useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import axiosConfig from "../Service/axiosConfig"
import { useNavigate } from 'react-router-dom';
import AlertDeletePopupModal from '../Components/PopupModal/AlertDeletePopupModal';
import { DeletePopupModal, FormParser, PopupModal } from '../Components';
import Masters from "../Components/masters.json"

const Requirementcontsnt = ({ Data, GetData }) => {
    const navigate = useNavigate();
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [popupType, setpopupType] = useState("");
    const [RowId, SetRowId] = useState()
    const [data, SetData] = useState({});
    const [requirementShow, setRequirementShow] = useState(false)
    const [Message, setMessage] = useState("")
    const [error, setError] = useState(null);
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")
    }
    const HandleEdit = (id) => {
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            SetData({
                ...res.data,
            })
            setRequirementShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = async (id) => {
        await axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(() => {
            SetAlertDeletePopupType("error")
            setDeletepopupType("success")
            GetData && GetData();
        }).catch((error) => {
            setDeletepopupType("error")
            SetAlertDeletePopupType("error")
            console.log(error)
        })
    }
    const handlShow = (id) => {
        navigate(`/dashboard/masters/Audition-applications/${id}/`)
    }
    const StatusChange = (status) => {
        axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, { requirement_status: status }).then(res => {
            console.log(res)
            GetData && GetData()
            setRequirementShow(false)
            setMessage("Requirement Updated SuccessFully!")
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    }
    const formSubmit = (formData, setFieldErrors) => {
        let OrderTopost;
        OrderTopost = {
            ...formData,
            audition: Data?.audition?.id,
            approval_status: "requested",
        };
        if (formData?.from_age >= formData?.to_age) {
            let errorMessage = "From age must be less than To age."
            setFieldErrors(prev => ({ ...prev, ['from_age']: errorMessage, ['to_age']: errorMessage }));
        } else {
            axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, OrderTopost).then(res => {
                console.log(res)
                GetData && GetData()
                setRequirementShow(false)
                setMessage("Requirement Updated SuccessFully!")
                setpopupType("success");
            }).catch(error => {
                console.log(error.response)
            })
        }
    };
    return (
        <>
            <div className='col-4'>
                <Card className="bax-shadow" style={{ position: "relative" }}>
                    <Card.Body>
                        <Card.Title className="card-title-custom">Role - {Data.character}({Data.id})</Card.Title>
                        <Card.Text>
                            <div className="applictn-dtls">
                                {Data.gender && <div className="apply-content">
                                    Gender : <span className="apply-data"> {Data.gender}</span >
                                </div>}
                                <div className="apply-content">
                                    Age : <span className="apply-data"> {Data.from_age} - {Data.to_age} years</span>
                                </div>
                                {Data.budget && <div className="apply-content">
                                    Budget : <span className="apply-data"> {Data.budget ? Data.budget : 0}</span>
                                </div>}
                                {Data.no_of_requirements && <div className="apply-content">
                                    No. of Positions : <span className="apply-data"> {Data.no_of_requirements}</span>
                                </div>}
                                <div className="apply-content">
                                    Preffered Languages: <span className="apply-data"> {Data?.loc_lang?.languages ? Data?.loc_lang?.languages : "Any Language"}</span>
                                </div>
                                <div className="apply-content">
                                    Preffered Location: <span className="apply-data"> {Data?.loc_lang?.city
                                        ? Data.loc_lang.city : Data?.loc_lang?.state ? Data.loc_lang.state : "Any Location"
                                    }
                                    </span>
                                </div>
                                {Data.specification && <div className="apply-content">
                                    Specification: <span className="apply-data"> {Data.specification}</span>
                                </div>}
                            </div>
                        </Card.Text>
                        <Card.Footer className="text-center" style={{ background: "none", borderTop: "none", paddingLeft: '0px' }}>
                            <button className="btn btn-info w-50" onClick={() => HandleEdit(Data.id)}>Edit</button>
                            <button className="btn btn-secondary w-50" onClick={() => DeleteAlert(Data.id)}>Delete</button>
                            <button className="btn btn-secondary w-50" onClick={() => StatusChange(Data?.requirement_status === "closed" ? "live" : "closed")} disabled={Data?.approval_status !== "approved"}>{Data?.requirement_status === "live" ? "Closed" : "Re open"}</button>
                            <button type="button" className="btn btn-primary w-50" onClick={() => handlShow(Data.id)}>Applications({Data.applications})</button>
                        </Card.Footer>
                    </Card.Body>
                </Card>
            </div>
            {AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Requirement Deleted Successfully"} />
            }
            {popupType != "" &&
                <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message={Message} error={error} />
            }
            <Modal
                size="lg"
                show={requirementShow}
                onHide={() => {
                    SetData({})
                    setRequirementShow(false)
                }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Audition Requirements
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={Masters.AditionRequirements} formData={data} formSubmit={formSubmit} error={Error} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export { Requirementcontsnt }
