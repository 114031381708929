import React, { useEffect, useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import axiosConfig from "../Service/axiosConfig"
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import Masters from "../Components/masters.json"
import { MdOutlineMoreVert, MdOutlineRemoveRedEye, MdOutlineWatchLater } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import AlertDeletePopupModal from '../Components/PopupModal/AlertDeletePopupModal';

const ProfileAuditions = ({ ProfileData, can_edit_profile }) => {
    console.log(ProfileData)
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [Show, setShow] = useState(false)
    const [formData, setFormdata] = useState({
        "banner_name": "",
        "project_type": "",
        "director_name": "",
        "director_mobile_no": "",
        "producer_name": "",
        "producer_mobile_no": "",
        "contact_name": ProfileData?.user?.first_name,
        "contact_designation": "",
        "contact_mobile_no": ProfileData?.mobile_number,
        "contact_mail": ProfileData?.user?.email,
        "music_director": "",
        "hero": "",
        "platform": "",
        "zoner": "",
        "budget": 0,
        "end_date": "",
        "state": "",
        "city": "",
        "languages": []
    });
    const [AudiData, setAudiData] = useState([]);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const HandleShow = () => {
        setShow(true)
    }
    const HandleClose = () => {
        setShow(false)
        GetData()
    }
    const formSubmit = (Data, setFieldErrors) => {
        console.log(formData, "jdshf", Data)
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const Datatosend = {
            ...Data,
            start_date: formattedDate
        }
        axiosConfig.post(`/accounts/auditions/?profile_id=${ProfileData?.id}`, Datatosend).then(res => {
            console.log(res)
            HandleClose()
        }).catch(error => {
            console.log(error.response)
        })
    };
    function getProjectType(status) {
        switch (status) {
            case 'Movies':
                return '#f5d8ff';
            case 'OTT':
                return '#c1c1cc';
            case 'Demo':
                return '#c1c1cc';
            case 'TV_Serials':
                return '#ffd8b1';
            case 'Web_Series':
                return '#b3ecff';
            case 'Music_Albums':
                return '#f0e68c';
            case 'Cover_Songs':
                return '#ffcccb';
            case 'Item_Song':
                return '#ff69b4';
            case 'Short_Films':
                return '#b2fab4';
            case 'Ads':
                return '#ffdab9';
            case 'Modelling':
                return '#e0ffff';
            case 'Audition':
                return '#ffb6c1';
            default:
                return '#ffffff';
        }
    }
    function getProjectTypeColor(status) {
        switch (status) {
            case 'Movies':
                return '#8b008b';
            case 'OTT':
                return '#696969';
            case 'Demo':
                return '#696969';
            case 'TV_Serials':
                return '#8b4513';
            case 'Web_Series':
                return '#4682b4';
            case 'Music_Albums':
                return '#bdb76b';
            case 'Cover_Songs':
                return '#a52a2a';
            case 'Item_Song':
                return '#c71585';
            case 'Short_Films':
                return '#228b22';
            case 'Ads':
                return '#d2691e';
            case 'Modelling':
                return '#20b2aa';
            case 'Audition':
                return '#db7093';
            default:
                return '#ffffff';
        }
    }
    const timeAgo = (dateString) => {
        const now = new Date();
        const pastDate = new Date(dateString);
        const secondsAgo = Math.floor((now - pastDate) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);

        if (daysAgo > 0) return `${daysAgo}d ago`;
        if (hoursAgo > 0) return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
        if (minutesAgo > 0) return `${minutesAgo}min${minutesAgo > 1 ? 's' : ''} ago`;
        return `${secondsAgo}s`;
    }
    const handleNavigate = (id, type) => {
        console.log(type)
        if (type === "view") {
            Navigate(`/dashboard/masters/Auditions/${id}/edit`)
        } else {
            Navigate(`/dashboard/masters/Auditions/${id}/edit`)
        }
    }
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")

    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = (id) => {
        console.log(id)
        axiosConfig.delete(`/accounts/auditions/${id}/`).then(res => {
            console.log(res)
            setDeletepopupType("success")
            SetAlertDeletePopupType("error")
            GetData()
        }).catch(error => {
            console.log(error)
        })
    }
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/auditions/?profile=${ProfileData?.id}`);
            setAudiData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetData();
    }, []);
    return (
        <>
            {loading ? (
                <div className="loader-container">
                    <img src={require('./images/loader.gif')} alt="Loading..." />
                </div>
            ) : (
                <div className="container">
                    {can_edit_profile &&
                        <div className="flri">
                            <button className="btn btn-default mb-2" onClick={HandleShow}>Add New Audition</button>
                        </div>}
                    <div className="row">
                        {AudiData.map((Audition, index) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={index} >
                                <div className="card card-style" style={{ backgroundColor: getProjectType(Audition.project_type) }}>
                                    <div className="card-types" style={{ backgroundColor: getProjectTypeColor(Audition.project_type) }}>{Audition.project_type ? Audition.project_type : "---"}</div>
                                    <div className="audi-edit">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <MdOutlineMoreVert size={25} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleNavigate(Audition.id, "edit")}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => DeleteAlert(Audition.id)}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='card-content'>
                                        {Audition.banner_name ? (
                                            <h4 className="banner-title">
                                                {Audition.banner_name}
                                            </h4>
                                        ) : (
                                            <div style={{ padding: '20px' }}></div>
                                        )}

                                        <div className="card-descrt">
                                            <div className="card-descrt-inner" style={{ height: "290px" }}>
                                                <div className="project_title">
                                                    <div className="audi-id">AD{Audition.id && Audition.id}</div>
                                                    <div>
                                                        <div className='Audi-project-title'>{Audition.project_title && Audition.project_title}</div>
                                                        <div className='Audi-description'>{Audition.description && Audition.description}</div>
                                                    </div>
                                                </div>
                                                <div className="card-audi">
                                                    {Audition.platform &&
                                                        <div className="audi-label" style={{ background: '#b00de9' }}>{Audition.platform}</div>
                                                    }
                                                    {Audition.zoner &&
                                                        <div className="audi-label" style={{ background: '#671640' }}>{Audition.zoner}</div>
                                                    }
                                                    {Audition.loc_lang.city &&
                                                        <div className="audi-label" style={{ background: '#3cb371' }}>{Audition.loc_lang.city}</div>
                                                    }
                                                    {Audition.loc_lang.languages &&
                                                        <div className="audi-label" style={{ background: '#db7093' }}>{Audition.loc_lang.languages}</div>
                                                    }
                                                </div>
                                                <div className="card-detail">
                                                    <div className="card-botton-centent">
                                                        <div className="card-cont-title">Producer</div>
                                                        <div className='card-cont-detail'>{Audition.producer_name ? Audition.producer_name : "-"}</div>
                                                    </div>
                                                    <div className="card-botton-centent">
                                                        <div className="card-cont-title">Director</div>
                                                        <div className='card-cont-detail'>{Audition.director_name ? Audition.director_name : "-"}</div>
                                                    </div>
                                                    {Audition.hero && <div className="card-botton-centent">
                                                        <div className="card-cont-title">Hero</div>
                                                        <div className='card-cont-detail'>{Audition.hero ? Audition.hero : "-"}</div>
                                                    </div>}
                                                </div>
                                                <div className="auditionList-require">
                                                    <div className="card-cont-title">Requirement:</div>
                                                    <div className='card-cont-detail'>
                                                        {Audition.generated_requirement && Audition.generated_requirement.trim() !== "" ? Audition.generated_requirement : "No requirements available"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-bottom">
                                                <div className="card-bottom-inner">
                                                    <div className="card-bottom-lefts">
                                                        <div className="card-cont-detail" style={{ display: "flex", alignItems: "center" }}><MdOutlineWatchLater size={20} /><span style={{ marginLeft: "2px" }}>{timeAgo(Audition?.created_at)}</span></div>
                                                        <div className="card-cont-detail" style={{ display: "flex", alignItems: "center" }}><MdOutlineRemoveRedEye size={20} /><span style={{ marginLeft: "2px" }}>{Audition.views}</span></div>
                                                    </div>
                                                    <div className="card-bottom-right">
                                                        <button type="button" className="btn btn-primary" onClick={() => handleNavigate(Audition.id, "view")}>View</button>
                                                        {/* <button type="button" className="btn btn-secondary" onClick={() => shareContent("share", Audition.id)}>Share</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </div >
                        ))}
                    </div>
                </div>
            )}
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={Show} onHide={HandleClose} className="edit-profile-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Create Audition</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={Masters.Audition} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle="Create Audition" button="btn-block" />
                </Modal.Body>
            </Modal>
            {
                deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Delete Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} SetAlertDeletePopupType={SetAlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
        </>
    )
}

export { ProfileAuditions }
