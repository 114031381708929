import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json"
import axiosConfig from "../Service/axiosConfig"
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal } from 'react-bootstrap';
import { Requirementcontsnt } from "./Requirementcontsnt";

const AuditionRequirement = ({ Code }) => {
    let { id } = useParams();
    console.log(id, "iddddd")
    const navigate = useNavigate();
    const [key, setKey] = useState('Info');
    const [readOnly, setReadOnly] = useState(true);
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [Data, SetData] = useState({});
    const [Message, setMessage] = useState("")
    const GetData = () => {
        setLoading(true)
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            SetData({
                ...res.data,
            })
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }
    const formSubmit = (formData, setFieldErrors) => {
        console.log(Data, "data", formData, key)
        let OrderTopost;
        if (key === "Status") {
            OrderTopost = {
                approval_status: formData?.approval_status,
                requirement_status: formData?.requirement_status
            };
        }
        axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, OrderTopost).then(res => {
            console.log(res)
            GetData()
            setMessage("Requirement Updated SuccessFully!")
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "profile") {
            navigate(`/dashboard/users/${link}/edit`)
        }
        if (type === "back") {
            navigate(`/dashboard/masters/Audition-requirements/`)
        }
    }
    useEffect(() => {
        GetData()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="main-container">
                    <div className="content-area">
                        <div className="client-options">
                            <BiArrowBack size={30} onClick={() => handleNavigate("", "back")} className="option-icon" />
                        </div>
                        <div className="client-info">
                            <div className="contact-detile">
                                Id : <div className="data-text">AD{Data.audition?.id}</div>
                            </div>
                            <div className="contact-detile">
                                Banner Name : <div className="data-text">{Data.audition?.banner_name}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Name : <div className="data-text">{Data.audition?.contact_name}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Designation Name : <div className="data-text">{Data.audition?.contact_designation}</div>
                            </div>
                            <div className="contact-detile">
                                Contact Mobile No : <a href={`tel:+${Data.audition?.contact_mobile_no}`} className="data-text">{Data.audition?.contact_mobile_no}</a>
                            </div>
                            <div className="contact-detile">
                                Profile Details : <div className="data-text">{Data?.audition?.profile?.first_name} ({Data?.audition?.profile?.hcc_id})</div>
                            </div>
                            <div className="contact-detile">
                                Start Date : <div className="data-text">{Data.audition?.start_date}</div>
                            </div>
                        </div>
                        <div className="client-form">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                            >
                                <Tab eventKey="Info" title="Info">
                                    <div className="projects-container">
                                        {Data &&
                                            <Requirementcontsnt Data={Data} GetData={GetData} />
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey="Status" title="Status">
                                    <div className="projects-container">
                                        <FormParser modelObject={Masters.RequirementStatus} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message={Message} error={error} />
                }
            </DashboardLayout >
        </>
    )

}
export { AuditionRequirement }